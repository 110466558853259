import React from "react";
import classNames from "classnames/bind";
import styles from "./About.module.css";
import photo1 from "./assets/impact-groepsfoto.jpg";
import tijdschema from "./assets/programma.png";
import { Create } from "@material-ui/icons";

const cx = classNames.bind(styles);

const About = (props) => {
  return (
    <div id={"about"} className={cx("base")}>
      <div className={cx("container")}>
        <section>
          <div className={cx("news-item")}>
            {/* <img src={photo2} alt="" /> */}
            <img src={photo1} alt="" />
            {/* <img src={photo3} alt="" /> */}

            {/* <img src={photo5} alt="" /> */}
            {/* <img src={photo4} alt="" /> */}
            <div>
              <h3>22-27 juli 2025</h3>
              <h4>Vlissingen</h4>

              <h4>Hoofdspreker:</h4>
              <em>Douglas Gausi</em>
              <h4>Geïntereseerd?</h4>
              <small>Aanmelding binnenkort mogelijk</small>
              {/* <small>
                Meld je aan vóór <strong>3 juli</strong>
              </small> */}
              {/* <button
                type="submit"
                onClick={() =>
                  (window.location =
                    "https://registerseat.com/at/impact-nl-2024")
                }
              >
                Aanmelden <Create />
              </button> */}
            </div>
          </div>

          <div className={cx("text")}>
            <h1>IMPACT Nederland 2025</h1>
            <p>
              In 2025 zal van 22 t/m 27 juli IMPACT Nederland weer plaatsvinden.
              In samenwerking met de adventgemeente in Vlissingen zal een groep
              jongeren op creatieve manieren mensen in Heerlen in contact
              brengen met het goede nieuws over onze Heer.
            </p>

            <h2>Veelgestelde vragen</h2>

            <h4>Voor welke leeftijden is IMPACT bedoeld?</h4>
            <p>
              Mensen tussen de 16 en 40 jaar behoren tot onze voornaamste
              doelgroep. Toch willen we niemand uitsluiten. Als u jong van hart
              bent, bent u ook van harte welkom.
            </p>

            <h4>Wie is de hoofdspreker?</h4>
            <p>
              Dit jaar zal Douglas Gausi uit Engeland aanwezig tijdens IMPACT!
            </p>
            <p>
              Douglas Gausi, oorspronkelijk uit Zambia en woonachtig in
              Nottingham, is een gepassioneerde, sociale, jonge blogger met een
              missie: het aantrekkelijk maken van het Woord van God voor
              niet-christenen en jongeren. Hij is heel actief in zijn locale
              gemeente en in de kerk in noord-england.
            </p>

            <p>
              Naast zijn passie voor bediening houdt Douglas van lezen, bloggen,
              het creëren van online content en joggen in zijn vrije tijd.
            </p>

            <h4>Hoe ziet het programma er ongeveer uit?</h4>
            <p>
              Het programma wisselt elk jaar af. Klik op de link om een
              overzicht van het programma van afgelopen jaar te zien:{" "}
              <a href={tijdschema}>
                <strong>programma</strong>
              </a>
              .
            </p>

            <h4>Hoeveel kost het?</h4>
            <p hidden>
              De prijzen worden nog berekend. Volg ons op sociale media om zsm
              op de hoogte zij wanneer de prijzen bekend zijn.
            </p>

            {/* <table>
              <tr>
                <th colSpan="2">
                  <strong>Registratie, accommodatie en maaltijden</strong>
                </th>
              </tr>
              <tr>
                <th>
                  <strong>Student</strong>
                </th>
                <td>€154,-</td>
              </tr>
              <tr>
                <th>
                  <strong>Werkende</strong>
                </th>
                <td>€179,-</td>
              </tr>
            </table> */}

            <h4>Hoe zit het met accomodatie?</h4>
            <p>
              Er zal gezamenlijke accommodatie voor alle deelnemers geregeld
              worden. Specifieke details daarvan volgen nog.
            </p>
            {/* <h4>Hoe zit het met accomodatie?</h4>
            <p>
              Dit jaar zijn er drie accomodatie opties op de camping waar we
              zullen slapen.
            </p>
            <ul>
              <li>
                Op het Vakantiepark Koningshof kamperen in een{" "}
                <strong>zelf meegebrachte tent</strong> (64 euro pp voor 5
                nachten)
              </li>
              <li>
                Op het Vakantiepark Koningshof in een{" "}
                <strong>Premium Lodge</strong> (230 euro pp voor 5 nachten).{" "}
                <br /> <strong>Let op!</strong> Er is een beperkte aantal
                slaapplekken in de Premium Lodges. Dus{" "}
                <strong>meld je zo snel mogelijk aan</strong> als je in een
                chalet wilt slapen (of neem zsm contact met ons op)!
              </li>
              <li>
                Daarnaast is het mogelijk om voor eigen accommodatie te zorgen.
              </li>
            </ul>

            <h4>Prijzen overzicht:</h4>
            <table>
              <tr>
                <th>
                  <strong>Plekje voor je (eigen) tent</strong>
                </th>
                <td>€64,-</td>
              </tr>
              <tr>
                <th>
                  <strong>Chalet ('Premium Lodge')</strong>
                </th>
                <td>€230,-</td>
              </tr>
            </table> */}

            {/* <h4>Tot wanneer kan ik me nog aanmelden?</h4>
            <p>
              Aanmelding is nog tot 9 juli mogelijk, maar als je in een chalet
              wilt slapen raden we aan om je zo snel mogelijk aan te melden om
              je plek in een chalet te bevestigen.
            </p> */}

            <h4>Hoeveel mensen komen er ongeveer?</h4>
            <p>We verwachten een groep van ongeveer 60 man.</p>

            <h4>
              Moet ik verplicht mee doen met alle onderdelen in het programma?
            </h4>
            <p>
              Geen programma onderdeel is verplicht, maar zo veel mogelijk
              meedoen is dé manier om zo veel mogelijk uit je Impactervaring te
              halen.
            </p>

            <h4>Is het eten op IMPACT vegetarisch/vegan?</h4>
            <p>
              Ja, er zal vegetarisch eten zijn; zelfs veganistisch eten zal
              beschikbaar zijn.
            </p>

            <h4>Als ik doneer, waar gaat het geld naartoe?</h4>
            <p>
              De donaties zorgen ervoor dat de deelnemerskosten laag blijven
              terwijl alle kosten toch bedekt worden. Donaties worden onder
              andere uitgegeven aan eten, onderkomen en evangelisatiemateriaal
              (boeken, stands in het centrum van Heerlen, Heatlh Expo, etc.).{" "}
              <a href="mailto:nederland@impactgeneration.org">Mail ons</a>, voor
              een gedetailleerd overzicht van de begroting van IMPACT Nederland
              2025.
            </p>
          </div>
        </section>
      </div>
    </div>
  );
};

export default About;
