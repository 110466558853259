import React, { useState } from "react";
import classNames from "classnames/bind";
import styles from "./HeroSection.module.css";
import { KeyboardArrowDown, PlayCircleOutline } from "@material-ui/icons";

import logo from "../../assets/logo.svg";
import ReactPlayer from "react-player/youtube";
import Button from "../../components/Button/Button";
import useToggle from "../../useToggle";
import { scrollToId } from "../NavBar/NavBar";
import Chip from "../../components/Chip/Chip";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const cx = classNames.bind(styles);

const HeroSection = (props) => {
  const [videoPlaying, toggleVideoPlaying] = useToggle(false);
  const [videoStarted, setVideoStarted] = useState(false);
  return (
    <div className={cx("base")}>
      <div className={cx("hero-slide")}>
        <div className={cx("video-wrapper")}>
          <div
            className={cx("video-overlay", { hide: videoPlaying })}
            onClick={() => {
              toggleVideoPlaying();
              if (!videoStarted) {
                setVideoStarted(true);
              }
            }}
          >
            {videoStarted && <PlayCircleOutline />}
          </div>
          <ReactPlayer
            playing={videoPlaying}
            onPause={toggleVideoPlaying}
            width="100%"
            height={450}
            url="https://www.youtube.com/watch?v=Iq45k4y5Wkk&t=1s&ab_channel=IMPACTNederland"
          />
        </div>

        <h1>Impact is er dit jaar weer!</h1>
      </div>
      <div className={cx("hero-box")}>
        <Chip className={"mb-8"}>UPDATE</Chip>
        <h2>IMPACT Nederland 2025</h2>

        <h3>22 - 27 juli 2025 🎉</h3>
        <h4>Vlissingen, Nederland</h4>
        <div className={cx("buttons")}>
          <Button big outline onClick={() => scrollToId("about")}>
            Meer informatie
          </Button>
        </div>
      </div>

      <div className={cx("hero-footer")}>
        <span className={cx("slogan")}>
          <strong>I</strong>nspiring <strong>M</strong>embers to{" "}
          <strong>P</strong>roclaim the <strong>A</strong>dvent of{" "}
          <strong>C</strong>hrist <strong>T</strong>ogether
        </span>
        <img src={logo} alt="" />
        <span className={cx("arrow")}>
          <KeyboardArrowDown fontSize={"large"} />
        </span>
      </div>
    </div>
  );
};

export default HeroSection;
