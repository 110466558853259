import React from "react";
import classNames from "classnames/bind";
import styles from "./Photos.module.css";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const cx = classNames.bind(styles);

// Function to dynamically import all images from the assets folder
const importAll = (r) => r.keys().map(r);
const photos = importAll(
  require.context("./assets", false, /\.(jpg|jpeg|JPG|png)$/)
);

// Function to shuffle array
const shuffleArray = (array) => {
  return array.sort(() => Math.random() - 0.5);
};

const Photos = () => {
  const shuffledPhotos = shuffleArray([...photos]); // Clone and shuffle the photos array

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3500,
    pauseOnHover: false,
    pauseOnFocus: true,
    speed: 1000,
    easing: "ease-out",
    responsive: [
      {
        breakpoint: 1324,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          centerMode: false,
        },
      },
      {
        breakpoint: 680,
        settings: {
          autoplaySpeed: 2500,
          slidesToShow: 1,
          centerMode: false,
        },
      },
    ],
  };

  return (
    <div id="about" className={cx("base")}>
      <div className={cx("container")}>
        <section>
          <Slider {...settings}>
            {shuffledPhotos.map((photo, index) => (
              <div key={index}>
                <img src={photo} alt={`Photo ${index}`} />
              </div>
            ))}
          </Slider>
        </section>
      </div>
    </div>
  );
};

export default Photos;
