import React from "react";
import classNames from "classnames/bind";
import styles from "../../sections/About/About.module.css";
import photo1 from "./assets/impact-groepsfoto.jpg";
import tijdschema from "./assets/programma.png";
import { Create } from "@material-ui/icons";

const cx = classNames.bind(styles);

const About = (props) => {
  return (
    <div id={"about"} className={cx("base")}>
      <div className={cx("container")}>
        <section>
          <div className={cx("news-item")}>
            <img src={photo1} alt="Impact Group Photo" />
            <div>
              <h3>July 22-27, 2025</h3>
              <h4>Vlissingen, Netherlands</h4>

              <h4>Main Speaker:</h4>
              <em>Douglas Gausi</em>
              <h4>Interested?</h4>
              <small>Registration opens soon</small>
            </div>
          </div>

          <div className={cx("text")}>
            <h1>About IMPACT Netherlands 2025</h1>
            <p>
              From July 22 to July 27, 2025, IMPACT Netherlands will take place
              again. In collaboration with the Adventist Church in Vlissingen, a
              group of young people will creatively connect people in Heerlen
              with the good news of our Lord.
            </p>

            <h2>Frequently Asked Questions</h2>

            <h4>What age group is IMPACT for?</h4>
            <p>
              IMPACT primarily targets people between 16 and 40 years old.
              However, we do not want to exclude anyone—if you are young at
              heart, you are warmly welcome!
            </p>

            <h4>Who is the main speaker?</h4>
            <p>
              This year, Douglas Gausi from England will be present at IMPACT!
            </p>
            <p>
              Douglas Gausi, originally from Zambia and living in Nottingham, is
              a passionate and social young man with a mission: making the Word
              of God appealing to non-Christians and young people. He is very
              active in his local church and within the church in North England.
            </p>
            <p>
              In his free time, Douglas enjoys reading, blogging, creating
              online content, and jogging.
            </p>

            <h4>What does the program look like?</h4>
            <p>
              The program varies each year. Click the link to see an overview of
              last year's program:
              <a href={tijdschema}>
                <strong>program</strong>
              </a>
              .
            </p>

            <h4>How much does it cost?</h4>
            <p hidden>
              Prices are still being calculated. Follow us on social media to
              stay updated when prices are announced.
            </p>

            <h4>What about accommodation?</h4>
            <p>
              Joint accommodation will be arranged for all participants.
              Specific details will follow soon.
            </p>

            <h4>How many people are expected to attend?</h4>
            <p>We expect around 60 participants.</p>

            <h4>Do I have to participate in all program activities?</h4>
            <p>
              No activities are mandatory, but participating as much as possible
              is the best way to get the most out of your IMPACT experience.
            </p>

            <h4>Is the food at IMPACT vegetarian/vegan?</h4>
            <p>
              Yes, vegetarian meals will be provided, and even vegan options
              will be available.
            </p>

            <h4>If I donate, where does the money go?</h4>
            <p>
              Donations help keep participant costs low while covering all
              necessary expenses. Contributions go toward:
              <ul>
                <li>- Food</li>
                <li>- Accommodation</li>
                <li>
                  - Evangelism materials (books, outreach stands in Heerlen,
                  Health Expo, etc.)
                </li>
              </ul>
              <a href="mailto:nederland@impactgeneration.org">Email us</a> for a
              detailed budget breakdown of IMPACT Netherlands 2025.
            </p>
          </div>
        </section>
      </div>
    </div>
  );
};

export default About;
